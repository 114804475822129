/* Footer.css */
.footer {
    background-color: #F4F4F4;
    padding: 2rem;
    color: #000;
}

.footer-container {
    display: flex;
    align-items: flex-start;
}

.footer-left,
.footer-right {
    display: flex;
    flex: 1;
    padding: 1rem;
}

.footer-left {
    flex: 2;
    color: #ccc;
}

.footer-right {
    flex: 1;
    display: flex;
    gap: 2rem;
}

.footer-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.footer-column-heading {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
}

.footer-links {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
}

.footer-links li {
    margin: 0.5rem 0;
}

.footer-links a {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.footer-links a:hover {
    text-decoration: underline;
}

.footer-social-icons {
    display: flex;
    gap: 1rem;
}

.social-icon {
    width: 24px;
    height: 24px;
}

.footer-bottom {
    margin-top: 2rem;
    text-align: right;
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
}

@media (max-width: 768px) {
    .footer {
        padding: 1.5rem;
    }

    .footer-container {
        flex-direction: column;
    }

    .footer-left,
    .footer-right {
        flex-direction: column;
        align-items: center;
    }

    .footer-right {
        gap: 1.5rem;
    }

    .footer-social-icons {
        justify-content: center;
    }

    .footer-bottom {
        text-align: center;
    }
}