* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Urbanist;
}

/* Base font size for most screens */
html {
  font-size: 16px;
}

/* For large screens (1920px and above) */
@media (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}

/* For smaller screens (below 1366px) */
@media (max-width: 1365px) {
  html {
    font-size: 14px;
  }
}