/* TrustedSection.css */
.trusted-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    margin: 3rem;
}

.trusted-left {
    flex: 1 0 50%;
}

.trusted-section-title {
    font-size: 2.5rem;
    color: #023047;
    margin: 0;
    font-weight: 600;
}

.trusted-section-subtitle {
    font-size: 1rem;
    color: #023047;
    margin-top: 1rem;
    font-weight: 400;
}

.schedule-button {
    padding: 0.5rem 1.2rem;
    background-color: #023047;
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    margin-top: 2rem;
    cursor: pointer;
    font-weight: 700;
    padding: 1rem;
}

.trusted-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 50%;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;
}

.brand-card {
    width: 210px;
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 0.5rem;
    box-shadow: 1px 2px 6.2px 0px #00000040;
}

.brand-card img {
    max-width: 80%;
    max-height: 80%;
}

@media (max-width: 768px) {
    .trusted-section {
        flex-direction: column;
        margin: 0;
    }

    .trusted-section-title {
        font-size: 2rem;
    }

    .trusted-section-subtitle {
        font-size: 0.8rem;
    }

    .trusted-left {
        text-align: center;
    }

    .brand-card {
        width: 120px;
    }

    .cards-container {
        margin-top: 1rem;
        justify-content: center;
    }
}