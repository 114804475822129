/* ServicesSection.css */
.services-section {
    padding: 2rem;
    margin: 3rem;
}

.services-section-header {
    text-align: center;
    margin-bottom: 2rem;
}

.services-section-title {
    font-size: 2.5rem;
    color: #023047;
    font-weight: 600;
}

.services-section-description {
    font-size: 1.5rem;
    color: #023047;
    margin: 1rem auto 0;
}

.services-cards-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
}

.service-card {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.service-card-image {
    width: 474px;
    height: 287px;
    margin-bottom: 1rem;
}

.service-card-title {
    font-size: 2.5rem;
    color: #023047;
    font-weight: 600;
}

.service-card-description {
    font-size: 0.9rem;
    color: #023047;
    margin: 1rem 0;
}

.service-card-button {
    background-color: #023047;
    color: #fff;
    padding: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 50%;
}

@media (max-width: 768px) {
    .services-cards-container {
        grid-template-columns: 1fr;
    }

    .service-card-image {
        width: 300px;
        height: auto;
    }

    .services-section-title {
        font-size: 2rem;
    }

    .services-section-description {
        font-size: 1rem;
    }

    .services-section {
        margin: 0;
    }

    .gallery-cards-container {
        width: 100%;
        max-width: 100%;
        flex-wrap: wrap;
    }

    .gallery-cards-container .gallery-card-image {
        width: auto;
        max-width: 90%;
    }

    .gallery-card {
        display: flex;
        justify-content: center;
    }

    .gallery-inner-container-2 {
        margin-top: 1rem;
    }
}


.gallery-cards-container {
    display: flex;
    width: 1060px;
    justify-self: center;
}

.gallery-inner-container-1 {
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.gallery-inner-container-2 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}